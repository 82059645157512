@import "../../../../../styles/colors";
@import "../../../../../styles/utils";

.box {
  width: pixelToRem(522);
  text-align: center;

  h2 {
    color: $BLACK_MEDIUM;
    font-size: pixelToRem(20);
    border-bottom: pixelToRem(1) solid #E9E9E9;
    margin: pixelToRem(10) pixelToRem(50) pixelToRem(20);
    padding-bottom: pixelToRem(20);
  }
  .content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: pixelToRem(10);
    padding-bottom: pixelToRem(20);
    gap: pixelToRem(24);
  }
  > label {
    flex: 0 0 pixelToRem(76);
    max-width: pixelToRem(76);
    margin-right: pixelToRem(8);
  }
}

.selectLabel {
  vertical-align: middle;
  font-size: pixelToRem(14);
  color: $PRIMARY_01;
  font-weight: 600;
}

.inputWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  > label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: pixelToRem(24);
    gap: pixelToRem(8);
  }

  > div {
    width: 100%;
  }
}