@import "../../../styles/colors";
@import "../../../styles/utils";

.selectLabel {
  font-size: pixelToRem(14);
  font-weight: 600;
  line-height: 1;
  color: $rac-black-medium;
}

.selectItemComponent {
  outline: none;
  color: $black;
  font-size: 0.875rem;
  line-height: pixelToRem(23);
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  height: pixelToRem(40);
}

.opacity02 {
  opacity: 0.2;
}

.searchInput {
  input {
    box-shadow: none !important;
    height: 1.5rem !important;
  }
}
